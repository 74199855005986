<template>
  <ApoCriaElemento />
</template>

<script>
import ApoCriaElemento from "@/components/documentacaoApoio/ApoioCriaElemento.vue";

export default {
  components: {
    ApoCriaElemento
  }
};
</script>
